<template>
  <div class="g-box g-code-add-box" v-loading="loading">
    <div class="m-form-inline-mn" data-m="add">
      <el-form
        :model="ruleForm"
        class="demo-form-inline"
        size="small"
        :rules="rules"
        ref="ruleForm"
        label-width="180px"
      >
        <el-row :gutter="20">
          <el-col :span="24">
            <h2 class="m-tt" v-if="isModeAdd">
              新增子字典-父字典:{{ $route.params.value }}
            </h2>
            <h2 class="m-tt" v-if="isModeEdit">
              子字典编辑-父字典:{{ $route.params.value }}
            </h2>
            <h2 class="m-tt" v-if="isModeInfo">
              子字典详情-父字典:{{ $route.params.value }}
            </h2>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="字典显示值" prop="value">
              <el-input
                v-model="ruleForm.value"
                placeholder="请输入字典显示值"
                :readonly="isModeInfo"
                maxlength="30"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="字典代码" prop="code">
              <el-input
                v-model="ruleForm.code"
                placeholder="请输入字典代码"
                :readonly="isModeInfo"
                maxlength="30"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="排序" prop="sort">
              <el-input
                v-model.number="ruleForm.sort"
                placeholder="请输入排序序号"
                :readonly="isModeInfo"
                maxlength="10"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="状态" prop="status">
              <el-switch
                v-model="ruleForm.status"
                active-text="已激活"
                inactive-text="锁定"
                :disabled="isModeInfo"
              >
              </el-switch>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="20">
            <el-form-item label="字典说明" prop="description">
              <el-input
                type="textarea"
                :rows="2"
                v-model="ruleForm.description"
                placeholder="请输入字典说明"
                :readonly="isModeInfo"
                maxlength="60"
                show-word-limit
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div class="m-btn-group">
      <el-button
        class="u-btn s-submit"
        size="small"
        type="primary"
        @click="submitForm('ruleForm')"
        v-if="!isModeInfo"
        :loading="submitLoading"
        :disabled="submitLoading"
      >
        提交
      </el-button>
      <el-button
        class="u-btn s-cancel"
        size="small"
        v-if="isModeInfo"
        @click="$router.go(-1)"
      >
        返回
      </el-button>
      <el-button
        class="u-btn s-cancel"
        size="small"
        v-else
        @click="returnPreviousPage"
      >
        返回
      </el-button>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import api from './../../../api'
import VueCookie from 'vue-cookie'

export default Vue.extend({
  name: 'code-info-add',
  components: {
  },
  directives: {},
  filters: {},
  props: {},
  data () {
    return {
      ruleForm: {
        code: '',
        value: '',
        sort: undefined,
        status: true,
        description: ''
      },
      loading: true,
      submitLoading: false,
      rules: {
        value: [
          { required: true, message: '请输入字典显示值', trigger: 'blur' },
          { pattern: /\S+/, message: '不能全为空格' }
        ],
        code: [
          { required: true, message: '请输入字典代码', trigger: 'blur' },
          //   { max: 15, message: '最多15位字符', trigger: 'blur' },
          { pattern: /\S+/, message: '不能全为空格' }
        ],
        sort: [
          { required: true, message: '请输入排序序号', trigger: 'blur' },
          { type: 'number', message: '排序序号必须为数字值' }
        ],
        status: [
          { required: true, trigger: 'change' }
        ]
      }
    }
  },
  computed: {
    isModeAdd () {
      return this.$route.name === 'code-info-add'
    },
    isModeEdit () {
      return this.$route.name === 'code-info-edit'
    },
    isModeInfo () {
      return this.$route.name === 'code-info-info'
    }
  },
  watch: {},
  beforeCreate () {
  },
  created () {
    this.init()
  },
  beforeMount () {
  },
  mounted () {
  },
  activated () {
  },
  deactivated () {
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  destroyed () {
    this.submitLoading = false
  },
  methods: {
    init () {
      if (this.isModeAdd) {
        this.loading = false
        // this.getAllOrgLevel()
      }
      if (this.isModeInfo) {
        this.getInfo(() => {
        })
      }
      if (this.isModeEdit) {
        this.getInfo(() => {
        })
      }
    },
    getInfo (callback = () => { }) {
      api.auth.postFindDictById({
        id: this.$route.params.sonId
      }).then(res => {
        this.ruleForm = res.data
      }).finally(() => {
        this.loading = false
      })
    },
    submitForm (formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.isModeAdd) {
            api.auth.postAddOrUpdateDict({
              ...this.ruleForm,
              parentCode: this.$route.params.code,
              dictParentId: this.$route.params.id,
              parkId: parseInt(VueCookie.get('parkId'))
            }
            ).then(() => {
              this.$router.go(-1)
            }).finally(() => {
              this.submitLoading = false
            })
          }
          if (this.isModeEdit) {
            api.auth.postAddOrUpdateDict({
              id: this.$route.params.sonId || '',
              dictParentId: this.$route.params.id,
              parentCode: this.$route.params.code,
              ...this.ruleForm
            }).then(() => {

              this.$router.go(-1)
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    },
    crop (res) {
      this.ruleForm.img = res.data
    }
  }
})
</script>

<style lang="scss" scoped>
@import "./../../../assets/css/global-variable.scss";
.g-code-add-box {
  position: relative;
  overflow: auto;
  height: 100%;
  background-color: #fff;
  border-radius: 5px;
}
.m-tt {
  margin-bottom: 30px;
  font-size: $fs-title;
  color: $fc-primary;
  font-weight: 500;
  line-height: 18px;
  text-indent: 10px;
  border-left: 4px solid $fc-theme;
  &.s-no-top {
    margin-top: 30px;
  }
}
.u-tree {
  float: left;
  min-width: 200px;
}
</style>

<style>
</style>
